import { Shippings as Base } from 'core/api/Shippings'
import { responseErrorCheck, api, ShippingParcel, ShippingParcelCreateRequest } from 'stylewhere/api'
import { AppStore, ShippingOperationConfig } from 'stylewhere/shared'

export class Shippings extends Base {
  static confirmParcel(
    operation: ShippingOperationConfig,
    data: ShippingParcelCreateRequest,
    force = false,
    async = false
  ) {
    if (data.attributes) {
      data.attributes.workstationCode = AppStore.defaultWorkstation?.code
    } else {
      data.attributes = { workstationCode: AppStore.defaultWorkstation?.code }
    }
    let path = ''
    if (force) path = '/force'
    else if (async) path = '/asyncExecution'
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/confirmParcel${path}`, data)
      .then(responseErrorCheck)
  }
  static closeParcel(operation: ShippingOperationConfig, checklistId: string) {
    return api
      .post<ShippingParcel>(`${this.getOperationEndpoint(operation)}/confirmChecklist`, { checklistId })
      .then(responseErrorCheck)
  }
}
